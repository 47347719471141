
.form-separator {
  align-items: center;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr auto 1fr;
}

.form-separator-line {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
}