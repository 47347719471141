@use "constants";

.game-completion-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem;
  justify-content: center;
  align-items: center;
  align-self: start;
}

.game-completion-summary-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.game-completion-summary {
  max-width: constants.$narrative-text-max-width;
}

.game-completion-img {
  width: auto;
  user-select: none;

  // extra small devices
  height: 13rem;
}

.murders-number {
  display: inline-block;
  color: red;
}

.completion-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
}

.completion-buttons-container button {
  min-width: max-content;
}

@media (min-width: constants.$small-width) {
  .game-completion-img {
    height: 16rem;
  }
}
