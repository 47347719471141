// small devices (landscape phones, 576px and up)
$small-width: 576px;

// medium devices (tablets, 768px and up)
$medium-width: 768px;

// large devices (desktops, 992px and up)
$large-width: 992px;

// extra large devices (large desktops, 1200px and up)
$extra-width: 1200px;

$app-background-color: black;

$screen-transition-speed: 500ms;

$app-sides-padding: 1rem;

$narrative-text-max-width: 600px;

$navigation_height: 4rem;
