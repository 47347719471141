.auth-form-wrapper {
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem 1rem;
  width: min(400px, 100%);
}

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.auth-form-header {
  font-size: 1.4rem;
  text-align: center;
}

.email-auth-form {
  display: flex;
  flex-direction: column;
}

.auth-continue-button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.auth-continue-button-wrapper .form-error-message {
  display: flex;
  justify-content: center;
}

.auth-continue-button-wrapper .loading-spinner {
  animation: rotate infinite 1.5s linear;
  position: absolute;
  right: 0.5rem;
}

.auth-form-hint {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.auth-form-hint-question {
  display: inline;
}

.auth-form-hint-link {
  text-decoration: none;
  color: #dc3545;
  padding: 0.25rem;
}

.supplementary-email-auth-form-part {
  margin-top: 1rem;
}

.auth-form-description {
  font-size: 0.9rem;
  display: flex;
  text-align: center;
}