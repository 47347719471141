@use "constants";

$navigation_height: constants.$navigation_height;
$footer_height: 1.75rem;

.trolley-problem-app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'DynaPuff', ui-rounded;
  position: relative;
  padding-top: constants.$navigation_height;
  line-height: 1.5;
}

@keyframes fade-in-animation {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.trolley-problem-app .active {
  animation: fade-in-animation constants.$screen-transition-speed;
}

.appearing-element {
  animation: fade-in-animation constants.$screen-transition-speed;
}

.trolley-problem-app .hidden {
  display: none;
}

$light_grey_color: rgba(232, 234, 237, 0.2);

a.app-link:not(:last-child) {
  margin-right: 0.625rem;
}

a.app-link i {
  width: 1rem;
  display: flex;
  justify-content: center;
}

.app-body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.navbar-burger {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 1.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: 1px solid $light_grey_color;
  border-radius: 0.375rem;
  margin-right: constants.$app-sides-padding;

  &:hover {
    background-color: $light_grey_color;
    border: 0;
  }

  &:active {
    background-color: rgba(186, 187, 190, 0.2);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-success-message {
  color: green;
}

.form-error-message {
  color: red;
}

$spinner-diagonal-size: 3rem;

@keyframes appearing-page-spinner {
  from {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.page-loading-spinner {
  font-size: $spinner-diagonal-size;
  height: $spinner-diagonal-size;
  width: $spinner-diagonal-size;
  align-self: center;
  margin: auto;
  animation: appearing-page-spinner 2500ms;
}

.rotating-spinner {
  animation: rotate infinite 1.5s linear;
}

.fetch-page-error {
  font-size: 1.2rem;
  align-self: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-left: constants.$app-sides-padding;
  padding-right: constants.$app-sides-padding;
  text-align: center;
}

.retry-btn {
  margin-top: 0.75rem;
  margin-left: auto;
  margin-right: auto;
}

.start-game-page-container {
  width: 100%;
  max-width: constants.$narrative-text-max-width;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.submission-form-page {
  width: 100%;
  max-width: constants.$narrative-text-max-width;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: start;
  margin-top: 2rem;
}

.submission-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-submission-button {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  margin-left: auto;
  margin-right: auto;
}

.form-submission-button-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-submission-button .loading-spinner {
  animation: rotate infinite 1.5s linear;
  position: absolute;
  left: calc(100% + 0.5rem);
}

@media (max-width: constants.$medium-width - 1px) {

  .form-submission-button {
    width: 100%;
  }

  .navbar-burger {
    margin-right: 0.75rem;
  }

  a.app-link:not(:last-child) {
    margin-right: 0.15rem;
  }
}
