@use "constants";

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  background-color: constants.$app-background-color;
  margin: 0;
  display: flex;
  min-height: 100%;
  min-width: 100%;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html {
  height: 100%;
  font-size: 16px;
}

#root {
  width: 100%;
}
