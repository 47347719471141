@use "constants";

$app_link_height: 2.5rem;

$navigation_height: constants.$navigation_height;

.app-header {
  overflow: hidden;
  transition: height 500ms ease;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 0;
  width: 100%;

  // the menu should be displayed on top of the body
  z-index: 1;
}

.app-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: constants.$navigation_height;
  padding-left: 0.75rem;
}

.secondary-navigation {
  display: flex;
  height: constants.$navigation_height;
  align-items: center;
}

@mixin menu-app-link {
  border-radius: 0;
  display: flex;
  justify-content: flex-start;
  margin-right: 0;
}

.static-menu-items a.app-link {
  @include menu-app-link;
}

@mixin menu-items {
  width: 100%;
  flex-direction: column;
}

.static-menu-items {
  @include menu-items;
}

@media (max-width: constants.$medium-width - 1px) {

  .secondary-navigation .dynamic-menu-item {
      display: none;
  }
}

@media (min-width: constants.$medium-width) {

  .static-menu-items a.dynamic-menu-item {
    display: none;
  }

  .navbar-burger {
    display: none;
  }

  .app-navigation {
    padding-left: constants.$app-sides-padding;
  }
}
