
.membership-info {
  margin-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.membership-header {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.membership-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.membership-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan-status-label {
  background-color: green;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.cancelling-plan-label {
  background-color: #e0a800;
}

.change-plan-status-btn {
  opacity: 0.75;
  font-size: 0.9rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  position: relative;
  display: flex;
  align-items: center;
}

.change-plan-status-btn .rotating-spinner {
  position: absolute;
  right: 0.5rem;
  margin: 0;
}

.membership-info-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
