@use "constants";

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity constants.$screen-transition-speed;
  pointer-events: none;
  z-index: 1;
}

.fade-exit-done {
  pointer-events: none;
}
