@use "constants";

.form-header {
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.font-description {
  font-size: 0.9rem;
}
