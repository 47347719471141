@use "constants";

.games-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 2rem constants.$app-sides-padding;
}

.games-page-heading {
  text-align: center;
  margin-bottom: 2rem;
}

.main-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.description-heading {
  color: rgba(255, 255, 255, 0.75);
}

@media (max-width: constants.$small-width - 1px) {

  .main-heading {
    margin-bottom: 0.75rem;
  }

  .games-page-heading {
    margin-bottom: 1.5rem;
  }
}
