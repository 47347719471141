@use "constants";

.collections-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1.75rem 1.25rem;
}

.collection {
  display: flex;
  flex-direction: column;
}

.collection-cover-wrapper {
  cursor: pointer;
}

.collection-cover-wrapper * {
  pointer-events: none;
  user-select: none;
}

.dynamic-cover-container {
  position: relative;
}

@keyframes played-cover-animation {
  from {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.played-cover-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.85rem;
  animation: played-cover-animation 4s forwards;
}

.cover-img-wrapper {
  display: flex;
}

.collection-cover {
  width: 16rem;
  height: 24rem;
  object-fit: cover;
  border-radius: 1rem;
}

.collection-title {
  text-align: center;
  margin-top: 0.75rem;
}

.start-button {
  margin-top: 0.75rem;
}

@media (max-width: constants.$small-width - 1px) {

  .collection-cover {
    width: 13rem;
    height: 19.5rem;
  }
}