@use "constants";

.manage-plan-page {
  align-self: flex-start;
  margin: 2rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: min(100%, 45rem);
}

.manage-plan-page-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  align-self: center;
}

.plans-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;

  // small devices
  align-items: center;
  flex-direction: column;
}

.plan-container {
  width: min(100%, 21rem);
  padding: 1.25rem;
  border-radius: 1rem;
  background-color: rgba(26, 30, 45, 0.75);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.plan-name {
  font-size: 1.75rem;
}

.plan-price {
  display: flex;
  align-items: center;
}

.price-label {
  font-size: 1.75rem;
  line-height: 1.75rem;
  margin-right: 0.25rem;
}

.price-time-interval {
  align-self: flex-end;
}

.plan-features {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.plan-feature > i {
  color: green;
  margin-right: 0.25rem;
}

.manage-plan-btn {
  margin-top: auto;
}

.manage-plan-btn .loading-spinner {
  animation: rotate infinite 1.5s linear;
  position: absolute;
  right: 0.5rem;
}

@media (min-width: constants.$medium-width) {

  .plans-container {
    align-items: initial;
    flex-direction: row;
  }
}