@use "constants";

.home-page-main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

.game-instruction-container {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .game-instruction {
    max-width: constants.$narrative-text-max-width;
    text-align: center;
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

.start-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.main-start-button {
  font-size: 1.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

.home-page-footer {
  width: 100%;
  display: flex;
  font-size: 12px;
  bottom: 0;
  left: 0;
}

.footer-links-container {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  color: rgba(255, 255, 255, 0.75);
}

.footer-link {
  text-align: center;
  text-decoration: inherit;
  color: inherit;
}

.terms-of-service-link {
  margin-left: auto;
}

.privacy-policy-link {
  margin-right: auto;
}

