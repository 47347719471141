
.forgot-password-link-wrapper {
  font-size: 0.9rem;
  justify-content: center;
  display: flex;
  padding-top: 0.5rem;
}

.forgot-password-link {
  text-decoration: none;
  color: #dc3545;
  padding: 0.25rem;
  margin-bottom: -0.25rem;
}
