@use "constants";

.choice-container {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.options-container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  // extra small devices
  flex-direction: column;
}

.choice-option {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0;
  cursor: pointer;

  // extra small devices
  max-height: 50%;
  max-width: 100%;
}

.option-background-img {
  display: block;
  user-select: none;
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  margin-left: auto;
  margin-right: auto;
}

.option-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
}

.option-img {
  position: absolute;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

.option-label {
  width: 100%;
  position: absolute;
  bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.label-text {
  background-color: white;
  color: black;
  width: fit-content;
  padding: 5px;
  border-radius: 10px;
  font-size: min(3.25vmin, 1rem);
}

.blood-img-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.blood-img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  user-select: none;
}

.sliding-up-panel {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  transition: transform 500ms ease-in-out;

  // small and medium devices
  transform: translateY(100%);
}

@keyframes slide-up-animation {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.displayed-panel {
  // small and medium devices
  transform: translateY(0);
}

.results-container {
  position: relative;
  padding: 1.1rem;
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;

  // extra small devices
  flex-direction: column;
}

.like-choice-wrapper {
  position: absolute;
  right: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #dc3545;
  display: flex;
  margin: 0.1rem 0.4rem;
  outline: none;
  padding: 0.35rem;
  text-shadow: 1px 1px 2px black;

  // small devices
  bottom: 100%;
}

@keyframes like-button-animation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.like-choice-wrapper i {
  font-size: 1.8rem;
}

.like-clicked i {
  animation: like-button-animation 0.45s ease-in-out forwards;
}

.like-label {
  font-size: 0.85rem;
}

.result-narrative {
  max-width: 45rem;
  text-align: center;
}

.next-button {
  // extra small devices
  width: 100%;
}

.result-narrative .percentage {
  display: inline;
  font-size: 1.2rem;
  position: relative;
  top: 0.1rem;

  // in order not to affect the layout of the text
  line-height: 0;
}

.selection-instruction {
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: min(4.095vmin, 1.3rem);
  width: max-content;
  z-index: 1;
  text-transform: uppercase;
  color: red;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 1);
  pointer-events: none;
  user-select: none;
}

@media (min-width: constants.$small-width) {
  .options-container {
    flex-direction: row;
  }

  .choice-option {
    max-height: 100%;
    max-width: 50%;
  }

  .results-container {
    flex-direction: row;
    font-size: 1.1rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .result-narrative .percentage {
    font-size: 1.3rem;
  }

  .next-button {
    font-size: 1.1rem;
    width: unset;
    min-width: 7rem;
  }

  .selection-instruction {
    left: 100%;
    transform: translateX(-50%);
  }
}

@media (min-width: constants.$large-width) {

  .sliding-up-panel {
    transform: none;
  }

  .displayed-panel {
    transform: none;
    transition: none;
  }

  .results-container {
    animation: slide-up-animation 500ms ease-in-out;
  }

  .like-choice-wrapper {
    bottom: 0;
    margin: 0.9rem;
  }
}
