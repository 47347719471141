
.checkout-success-page {
  align-self: flex-start;
  margin: 2rem 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: min(100%, 45rem);
}

.checkout-success-page-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  align-self: center;
}

.rotating-spinner-container {
  font-size: 3rem;
  height: 3rem;
  width: 3rem;
  align-self: center;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.open-games-btn-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.subscription-status-narrative {
  text-align: center;
}

.active-subscription-narrative {
  display: inline;
}
